// import Lenis from "@studio-freight/lenis";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
// import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollTrigger);

export default class Gsap {
  constructor() {
    let mm = gsap.matchMedia();

    gsap.fromTo(
      ".mv__inner-ttl",
      {
        autoAlpha: 1,
      },
      {
        autoAlpha: 0,
        scrollTrigger: {
          trigger: ".mv",
          start: "1% top",
          // end: "center 30%",
          end: "center 20%", //pin用
          // pin: true, //pin用
          scrub: true,
          invalidateOnRefresh: true,
        },
      }
    );
    gsap.fromTo(
      ".mv__inner-ttl02",
      {
        autoAlpha: 0,
      },
      {
        autoAlpha: 1,
        scrollTrigger: {
          trigger: ".mv",
          start: "1% top",
          end: "center 30%",
          scrub: true,
          invalidateOnRefresh: true,
        },
      }
    );

    //MEMO：初回公開時以外はコメントアウト解除
    // gsap.fromTo(
    //   ".scrollDown",
    //   {
    //     autoAlpha: 1,
    //   },
    //   {
    //     autoAlpha: 0,
    //     scrollTrigger: {
    //       trigger: ".mv",
    //       start: "1% top",
    //       end: "center 30%",
    //       scrub: true,
    //       invalidateOnRefresh: true,
    //     },
    //   }
    // );
    //MEMO：初回公開時以外はJS削除
    // gsap.fromTo(
    //   ".scrollDown",
    //   {
    //     autoAlpha: 1,
    //   },
    //   {
    //     autoAlpha: 0,
    //     scrollTrigger: {
    //       trigger: ".sec-access",
    //       start: "180px bottom",
    //       // start: "80px bottom",
    //       // end: "192px bottom",
    //       // scrub: true,
    //       invalidateOnRefresh: true,
    //       // markers: true,
    //     },
    //   }
    // );

    mm.add("(min-width: 768px)", () => {
      gsap.fromTo(
        ".mv__inner-img__shape",
        {
          width: "336px",
          height: "336px",
          borderRadius: "50%",
        },
        {
          width: "100%",
          height: "640px",
          borderRadius: "10px",
          scrollTrigger: {
            trigger: ".mv",
            start: "1% top",
            // end: "center 30%",
            end: "center 20%", //pin用
            pin: true, //pin用
            scrub: true,
            invalidateOnRefresh: true,
          },
        }
      );
      ScrollTrigger.refresh();
    });

    mm.add("(max-width: 767px)", () => {
      gsap.fromTo(
        ".mv__inner-img__shape",
        {
          width: "95%",
          height: "100%",
          borderRadius: "50%",
        },
        {
          width: "100%",
          height: "80vh",
          // height: "80vh",
          borderRadius: "10px",
          scrollTrigger: {
            trigger: ".mv",
            start: "1% top",
            // end: "center 30%",
            end: "bottom 30%", //pin用
            // end: `bottom ${innerHeight - 30}px`,
            pin: true, //pin用
            scrub: true,
            invalidateOnRefresh: true,
            // markers: true,
          },
        }
      );
    });
    // ScrollTrigger.refresh();

    const secConcept = document.querySelector(".sec-concept");

    //------------------
    // 背景 黄色ふわふわ円
    //------------------

    let tl_fuwaCircleObj = gsap.timeline({
      scrollTrigger: {
        trigger: secConcept,
        start: "25% bottom",
        end: "center top",
        // scrub: true,
        scrub: 1,
        invalidateOnRefresh: true,
      },
    });

    mm.add("(min-width: 768px)", () => {
      tl_fuwaCircleObj.to(".fuwa__circle-obj--1", {
        x: "5vw",
        y: "-40vh",
        scale: "2",
        autoAlpha: 0,
      });
    });

    mm.add("(min-width: 768px)", () => {
      tl_fuwaCircleObj.to(
        ".fuwa__circle-obj--2",
        {
          x: "-10vw",
          y: "-30vh",
          scale: "3",
          // y: "20vh",
          autoAlpha: 0,
        },
        "<"
      );
    });

    mm.add("(min-width: 768px)", () => {
      tl_fuwaCircleObj.to(
        ".fuwa__circle-obj--3",
        {
          x: "-10vw",
          y: "-50vh",
          scale: "5",
          autoAlpha: 0,
        },
        "<"
      );
    });

    mm.add("(min-width: 768px)", () => {
      tl_fuwaCircleObj.to(
        ".fuwa__circle-obj--4",
        {
          x: "-5vw",
          y: "-10vh",
          autoAlpha: 0,
          scale: "6",
        },
        "<"
      );
    });

    mm.add("(min-width: 768px)", () => {
      tl_fuwaCircleObj.to(
        ".fuwa__circle-obj--5",
        {
          y: "-50vh",
          x: "-15vw",
          scale: "3",
          autoAlpha: 0,
        },
        "<"
      );
    });

    mm.add("(min-width: 768px)", () => {
      tl_fuwaCircleObj.to(
        ".fuwa__circle-obj--6",
        {
          y: "-30vh",
          autoAlpha: 0,
          x: "-10vw",
          scale: "3",
        },
        "<"
      );
    });

    mm.add("(min-width: 768px)", () => {
      tl_fuwaCircleObj.to(
        ".fuwa__circle-obj--7",
        {
          y: "-40vh",
          autoAlpha: 0,
          x: "5vw",
          scale: "3",
        },
        "<"
      );
    });

    mm.add("(min-width: 768px)", () => {
      tl_fuwaCircleObj.to(
        ".fuwa__circle-obj--8",
        {
          y: "-10vh",
          autoAlpha: 0,
          x: "10vw",
          scale: "6",
        },
        "<"
      );
    });

    mm.add("(min-width: 768px)", () => {
      tl_fuwaCircleObj.to(
        ".fuwa__circle-obj--9",
        {
          y: "-30vh",
          autoAlpha: 0,
          x: "0vw",
          scale: "3",
        },
        "<"
      );
    });

    mm.add("(min-width: 768px)", () => {
      tl_fuwaCircleObj.to(
        ".fuwa__circle-obj--10",
        {
          y: "-60vh",
          autoAlpha: 0,
          x: "10vw",
          scale: "4",
        },
        "<"
      );
    });

    mm.add("(min-width: 768px)", () => {
      tl_fuwaCircleObj.to(
        ".fuwa__circle-obj--11",
        {
          y: "-20vh",
          autoAlpha: 0,
          x: "-5vw",
          scale: "4",
        },
        "<"
      );
    });
    mm.add("(max-width: 767px)", () => {
      tl_fuwaCircleObj.to(
        ".fuwa__circle-obj--1",
        {
          x: "5vw",
          y: "-80vh",
          scale: "4",
          autoAlpha: 0,
        },
        "<"
      );
    });

    mm.add("(max-width: 767px)", () => {
      tl_fuwaCircleObj.to(
        ".fuwa__circle-obj--2",
        {
          x: "-10vw",
          y: "-60vh",
          scale: "3",
          // y: "20vh",
          autoAlpha: 0,
        },
        "<"
      );
    });

    mm.add("(max-width: 767px)", () => {
      tl_fuwaCircleObj.to(
        ".fuwa__circle-obj--3",
        {
          x: "-10vw",
          y: "-50vh",
          scale: "5",
          autoAlpha: 0,
        },
        "<"
      );
    });

    mm.add("(max-width: 767px)", () => {
      tl_fuwaCircleObj.to(
        ".fuwa__circle-obj--4",
        {
          x: "-5vw",
          y: "-80vh",
          autoAlpha: 0,
          scale: "6",
        },
        "<"
      );
    });

    mm.add("(max-width: 767px)", () => {
      tl_fuwaCircleObj.to(
        ".fuwa__circle-obj--5",
        {
          y: "-100vh",
          x: "-15vw",
          scale: "3",
          autoAlpha: 0,
        },
        "<"
      );
    });

    mm.add("(max-width: 767px)", () => {
      tl_fuwaCircleObj.to(
        ".fuwa__circle-obj--6",
        {
          y: "-80vh",
          autoAlpha: 0,
          x: "-10vw",
          scale: "3",
        },
        "<"
      );
    });

    mm.add("(max-width: 767px)", () => {
      tl_fuwaCircleObj.to(
        ".fuwa__circle-obj--7",
        {
          y: "-100vh",
          autoAlpha: 0,
          x: "5vw",
          scale: "3",
        },
        "<"
      );
    });

    mm.add("(max-width: 767px)", () => {
      tl_fuwaCircleObj.to(
        ".fuwa__circle-obj--8",
        {
          y: "-80vh",
          autoAlpha: 0,
          x: "10vw",
          scale: "6",
        },
        "<"
      );
    });

    mm.add("(max-width: 767px)", () => {
      tl_fuwaCircleObj.to(
        ".fuwa__circle-obj--9",
        {
          y: "-80vh",
          autoAlpha: 0,
          x: "0vw",
          scale: "3",
        },
        "<"
      );
    });

    mm.add("(max-width: 767px)", () => {
      tl_fuwaCircleObj.to(
        ".fuwa__circle-obj--10",
        {
          y: "-60vh",
          autoAlpha: 0,
          x: "10vw",
          scale: "4",
        },
        "<"
      );
    });

    mm.add("(max-width: 767px)", () => {
      tl_fuwaCircleObj.to(
        ".fuwa__circle-obj--11",
        {
          y: "-20vh",
          autoAlpha: 0,
          x: "-5vw",
          scale: "4",
        },
        "<"
      );
    });

    //------------
    //CONCEPTセクション 背景の大きい黄色円
    //------------
    const conceptCircle = document.querySelector(".concept__circle");

    let tl_conceptCircle = gsap.timeline({
      scrollTrigger: {
        trigger: secConcept,
        start: "25% bottom",
        end: "bottom top",
        scrub: true,
        invalidateOnRefresh: true,
      },
    });

    mm.add("(min-width: 768px)", () => {
      tl_conceptCircle.to(conceptCircle, {
        width: "180%",
      });
      tl_conceptCircle.to(conceptCircle, {
        width: "0%",
      });
      tl_conceptCircle.scrollTrigger.refresh();
    });

    let tl_conceptCircleSP = gsap.timeline({
      scrollTrigger: {
        trigger: secConcept,
        start: "10% bottom",
        end: "120% top",
        scrub: true,
        invalidateOnRefresh: true,
      },
    });
    mm.add("(max-width: 767px)", () => {
      tl_conceptCircleSP.to(conceptCircle, {
        // width: "160svh",
        width: "300vw",
      });
      tl_conceptCircleSP.to(conceptCircle, {
        width: "0%",
      });
      tl_conceptCircle.scrollTrigger.refresh();
    });

    //------------------
    // CVボタン 背景色変更（SPのみ）
    //------------------

    // const cvContact = document.querySelector(".cv__contact");

    // gsap.to(cvContact, {
    //   scrollTrigger: {
    //     trigger: secConcept,
    //     start: "25% bottom",
    //     end: "bottom center",
    //     invalidateOnRefresh: true,
    //     toggleClass: {
    //       targets: cvContact,
    //       className: "-secondary",
    //     },
    //   },
    // });
    // gsap.to(cvContact, {
    //   scrollTrigger: {
    //     trigger: ".contact__info",
    //     start: "top bottom",
    //     end: "bottom+=74 bottom",
    //     invalidateOnRefresh: true,
    //     toggleClass: {
    //       targets: cvContact,
    //       className: "-secondary",
    //     },
    //   },
    // });

    //------------
    //Lenis処理
    //------------
    // const lenis = new Lenis({ wrapper: document.body });

    // lenis.on("scroll", ScrollTrigger.update);

    // gsap.ticker.add((time) => {
    //   lenis.raf(time * 1000);
    // });
    // gsap.ticker.lagSmoothing(0);
  }
}
