import "../../assets/scss/app.scss";
import gsap from "./utils/gsap";
import gnav from "./utils/gnav";
import dialog from "./utils/dialog";
import smoothScroll from "./utils/smoothScroll";
// import lenis from "./utils/lenis";

class Main {
  constructor() {
    // new gsap();
    new gnav();
    new dialog();
    new smoothScroll();
    // new lenis();
  }
}

class load {
  constructor() {
    // new gsap();
    // new lenis();
  }
}
window.addEventListener("DOMContentLoaded", () => {
  new Main();
});

window.addEventListener("load", () => {
  new load();
});
