export default class gnav {
  constructor() {
    const body = document.getElementById("body");
    let bodyOffsetY;

    const hamburger = document.getElementById("header__hamburger");
    const hamburgerBar = document.querySelectorAll(".header__hamburger-bar");
    const nav = document.getElementById("header__nav");
    const navClose = document.getElementById("header__navClose");
    const navLinks = document
      .getElementById("header__nav")
      .querySelectorAll("a");

    // //MEMO コンバージョンボタン表示時コメントアウト解除
    // const cvContact = document.querySelector(".cv__contact");
    // const cvContactLinks = document
    //   .querySelector(".cv__contact")
    //   .querySelectorAll("a");

    hamburger.addEventListener("click", () => {
      if (!nav.classList.contains("is-active")) {
        openMenu();
      } else {
        closeMenu();
      }
    });

    navClose.addEventListener("click", () => {
      closeMenu();
    });

    navLinks.forEach((navLink) => {
      const href = new URL(navLink.href);
      if (href.hash) {
        navLink.addEventListener("click", () => {
          if (nav.classList.contains("is-active")) {
            closeMenu();
          }
        });
      }
    });

    //     MEMO コンバージョンボタン表示時コメントアウト解除
    // cvContactLinks.forEach((cvContactLink) => {
    //   const href = new URL(cvContactLink.href);

    //   if (href.hash) {
    //     cvContactLink.addEventListener("click", () => {
    //       closeMenu();
    //     });
    //   }
    // });
    // }

    // メニューを開く
    function openMenu() {
      if (!nav || !body) return;
      // メニュー表示時のscroll制御
      scrollDisable();

      nav.classList.add("is-active");
      hamburger.classList.add("is-active", "js-navClose");
      hamburgerBar.forEach(function (item) {
        item.classList.add("is-active");
      });
      //MEMO コンバージョンボタン表示時コメントアウト解除
      // cvContact.classList.add("js-navActive");
    }

    // メニューを閉じる
    function closeMenu() {
      if (!nav || !body) return;
      // メニュー表示時のscroll制御
      scrollValidity();

      hamburger.classList.remove("is-active", "js-navClose");
      hamburgerBar.forEach(function (item) {
        item.classList.remove("is-active");
      });
      nav.classList.remove("is-active");

      //MEMO コンバージョンボタン表示時コメントアウト解除
      // if (cvContact.classList.contains("js-navActive")) {
      //   cvContact.classList.remove("js-navActive");
      // }
    }

    //スクロール許可
    function scrollValidity() {
      document.body.classList.remove("is-scrollLock");
      document.body.style.top = "";
      window.scrollTo(0, bodyOffsetY);
    }
    //スクロール制御
    function scrollDisable() {
      bodyOffsetY = window.scrollY;
      document.body.style.top = `${-bodyOffsetY}px`;
      document.body.classList.add("is-scrollLock");
    }
  }
}
