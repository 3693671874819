export default class dialog {
  constructor() {
    //------------
    //dialog modal
    //------------
    const dialog = document.querySelector(".l-dialog");
    const dialogOpen = document.querySelectorAll(".l-dialog__open");
    const dialogClose = document.querySelector(".l-dialog__close");

    const nav = document.getElementById("header__nav");

    let bodyOffsetY;

    //モーダル開くボタン押した時の処理
    dialogOpen.forEach(function (item) {
      item.addEventListener("click", () => {
        openDialog();
      });
    });

    //モーダル内 > CLOSEボタンを押した時の処理
    dialogClose.addEventListener("click", () => {
      //グロナビ開いた時のお問い合わせボタンクリック時は、モーダル閉じた時にスクロールを許可しない
      if (nav.classList.contains("is-active")) {
        closeDialog();
      } else {
        document.body.classList.remove("is-scrollLock");
        closeDialog();
      }
    });
    //モーダル内 > オーバーレイをクリックしたときモーダルを閉じる
    dialog.addEventListener("click", (event) => {
      if (event.target === dialog) {
        //グロナビ開いた時のお問い合わせボタンクリック時は、モーダル閉じた時にスクロールを許可しない
        if (nav.classList.contains("is-active")) {
          closeDialog();
        } else {
          document.body.classList.remove("is-scrollLock");
          closeDialog();
        }
      }
    });
    //escキーを押した時モーダルを閉じる
    dialog.addEventListener("cancel", () => {
      closeDialog();
    });

    function openDialog() {
      bodyOffsetY = window.scrollY;
      document.body.style.top = `${-bodyOffsetY}px`;
      dialog.showModal();
      document.body.classList.add("is-scrollLock");
    }

    function closeDialog() {
      dialog.close();
      document.body.style.top = "";
      window.scrollTo(0, bodyOffsetY);
    }
  }
}
